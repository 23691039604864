import React from "react"
import imgPortadaGuide from "@components/pageGuide/guides/guide86/image/imgPortadaGuia.png"
import img1 from "@components/pageGuide/guides/guide86/image/img.png"
import slider1 from "@components/pageGuide/guides/guide86/image/silder1.png"
import slider2 from "@components/pageGuide/guides/guide86/image/silder2.png"
import slider3 from "@components/pageGuide/guides/guide86/image/silder3.png"


const linkForm = (
  <span>
    Al registrarte, aceptas los términos y condiciones de nuestra{" "}
    <a
      style={{ color: "#2C5C82", textDecoration: "none" }}
      href="https://beexcc.com/politica-seguridad-privacidad.html"    >
      Política de Privacidad.
    </a>
  </span>
)

const data = {
  start: {
    support: "Guía | Inmobiliarias ",
    title: <h1 className="titles-underlined-ebook ebook-ventas">
      Cómo usar bots para precalificar compradores de viviendas
    </h1>,
    description: <p className="text-h2-ebook guide__description">
      Los bots ayudan a optimizar el proceso de precalificación de compradores al recopilar información clave de forma automática. Configura preguntas para identificar el interés y la capacidad de compra de cada prospecto. Usa flujos de conversación para filtrar contactos según sus respuestas.
    </p>,
    classtext: "guide__description-container",
    link: linkForm,
    href: "/ebook-9.pdf",
    brochure: "Guía",
    img: imgPortadaGuide,
  },

  slider: {
    title: "¿Qué encontrarás?",
    underlined: "encontrarás?",
    checklist: [
      {
        key: 1,
        text: "Automatización de preguntas clave para identificar necesidades.",
      },
      {
        key: 2,
        text: "Segmentación automática de leads según criterios de precalificación."
      },
      {
        key: 3,
        text: "Uso de bots para programar citas y visitas a propiedades.",
      },
      {
        key: 4,
        text: "Seguimiento centralizado de compradores potenciales.",
      },
      {
        key: 5,
        text: "Envío automático de información personalizada sobre propiedades.",
      },
    ],
    listPictures: [
      {
        key: 1,
        img: slider1,
      },
      {
        key: 2,
        img: slider2,
      },
      {
        key: 3,
        img: slider3,
      },
    ],
  },
  description: {
    cards: [
      {
        text: <p>
          Recuerda que, con esta guía, podrás saber cómo estructurar los mensajes para obtener datos sobre presupuesto, ubicación y preferencias. Asegúrate de integrar el bot con tu CRM para gestionar la información.
          <br /><br />
          Por último, descubre más detalles, ejemplos prácticos y herramientas recomendadas descargándola. Mejora tu proceso de captación de clientes.
        </p>,
        photo: img1,
        class: true,
      },
    ],
  },
}

export default data
